<template>
  <div class="home-page">
    <div class="banner">
      <div class="logo-wrapper">
        <!-- <img src="../assets/images/frame.png" alt=""> -->
        <h6>Nine Symbols</h6>
        <h4>9个符号学习平板</h4>
        <h5>
          英语发音新方法
          <br>
          2小时通读英语
        </h5>
        <button class="btn">立即咨询</button>
      </div>
    </div>
    <div class="main">
      <div class="title-wrapper">
        <p class="main-title">核心能力</p>
        <p class="sub-title">CORE COMPETENCE</p>
      </div>
      <div class="main-content centered">
        <ul>
          <li>
            <img src="../assets/images/main1.png" alt="img">
            <h5>9个符号学习</h5>
            <p>临阵磨枪，快速积累词汇量</p>
          </li>
          <li>
            <img src="../assets/images/main2.png" alt="img">
            <h5>9个符号学习</h5>
            <p>临阵磨枪，快速积累词汇量</p>
          </li>
          <li>
            <img src="../assets/images/main3.png" alt="img">
            <h5>9个符号学习</h5>
            <p>临阵磨枪，快速积累词汇量</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="about">
      <div class="title-wrapper">
        <p class="main-title">关于我们</p>
        <p class="sub-title">ABOUT US</p>
      </div>
      <div class="about-content">
        <h5 class="desc">
          9个符号
          <br>
          2小时通读英语
          <div></div>
        </h5>
        <div class="text">
          <p> 9个符号是英语注音体系，也称为《 全球音Global Phonetics》 获得了2022年获得中国唯一一个语言学发明专利</p>
          <p> 我们提供180多万词汇标注，课文同步跟读、双语国学/道德经等海量学习内容 帮助小孩/成年人解决哑巴英语开口难的问题 像学习汉子拼音一样，精准拼读英语单词、句子、文章</p>
        </div>
      </div>
    </div>
    <div class="footer">
      © 2011~2023 湖南数播科技有限公司 | 湘ICP备2023017089号-1
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
    
  }
}
</script>
<style scoped lang="scss">
@import '../styles/home.scss';
</style>